import React from 'react';
import { ControllerRenderProps, FieldPath } from 'react-hook-form';
import _ from 'lodash';
import {  Label } from 'reactstrap';
import { NcSlideConfigFormData, NcSlideshowFormData } from '../subpages/NcSlideshows/NcSlideshowDetails';
import { NarrowcastingHelper } from '../NarrowcastingHelper';

import { Controlled as CodeMirror } from 'react-codemirror2';
require('codemirror/mode/javascript/javascript');
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import classNames from 'classnames';

interface Props {
  field: ControllerRenderProps<NcSlideshowFormData, 'slides'>,
  fieldName: FieldPath<NcSlideConfigFormData>
  activeIndex: number
  label?: string
  placeholder?: string
  maxLength?: number
  readOnly?: boolean
}

export const NcSlideConfigJsonArea = ({ field, fieldName, activeIndex, label, readOnly }: Props) => {

  const val = NarrowcastingHelper.getValueOfSlideConfigField(field, activeIndex, fieldName) || '';

  return (
    <>
      <Label>{label}</Label>
      {/* <Input
        placeholder={placeholder}
        maxLength={maxLength}
        value={val}
        onChange={(e) => {
          NarrowcastingHelper.setValueOfSlideConfigField(field, activeIndex, fieldName, e.target.value)
        }}
      /> */}
      <CodeMirror
        value={ val}
        className={'jsonArea'}
        options={{
            mode: {
                name: "javascript",
                jsonld: true
            },
            theme: "material",
            lineNumbers: true,
            readOnly:readOnly
        }}
        onBeforeChange={(editor, data, value) => {
            // this.updateIos(value);
            // if (readOnly) {
            //     return;
            // }
            NarrowcastingHelper.setValueOfSlideConfigField(field, activeIndex, fieldName, value)
        }}
        editorDidMount={(editor) => {
            editor.refresh();
        }}


      />
    </>
  )
}
