import React, { useMemo, useState } from 'react';
import { Control, useController } from 'react-hook-form';
import { CmsItemFormData, proxifyContent } from '../CmsItemDetails';
import { Form, Profile, TranslateFunction, Widget } from '../../../../../../../domain';
import { Translations } from '../../../../../../translations';
import { MediaHelper } from '../../../../../../utils';
import styles from './CmsContentEditor.module.scss';
import _ from 'lodash';
import { Editor } from '@tinymce/tinymce-react';

import { FormSelector, MediaSelector, WidgetSelector } from '../../../../../../components';
import { AccordionPanel, CmsAccordion, CmsAccordionManager } from './CmsAccordionManager';
import { CmsVariationFormData } from '../CmsVariations/CmsVariationDetails';
import { Bucket } from '../../../../../../components/MediaManagerSelector/domain';
import $ from 'jquery';
import { CMS_CUSTOM_ICON_PACK } from './CmsCustomIcons';
import { TinyMceHelper } from './TinyMceHelper';
import { IMAGE_ALIGNMENT, IMAGE_SIZE,  } from './TinyMceHelper/ImagesHelper';

interface AccordionManagerConfig {
  enabled: boolean,
  editingAccordion?: CmsAccordion,
  editingElem?: any
}

interface Props {
  magazine: number,
  profile: Profile
  control: Control<CmsItemFormData | CmsVariationFormData>
  cmsBuckets: Bucket[]
}

type MediaSelectorMode = 'image' | 'audio' | 'video' | 'gallery'


interface MediaSelectorConfig {
  multipleSelection?: boolean,
  targetTypes?: Array<'images' | 'audios' | 'videos'>,
}

//  type InlineImageSize = IMAGE_SIZE.full | IMAGE_SIZE.large | IMAGE_SIZE.medium | IMAGE_SIZE.small | IMAGE_SIZE.original;


export const CmsContentEditor = ({ magazine, profile, control, cmsBuckets }: Props) => {

  const [mediaSelectorMode, setMediaSelectorMode] = useState<MediaSelectorMode | undefined>(undefined)

  const mediaSelectorConfig = useMemo<MediaSelectorConfig | undefined>(
    () => {
      switch (mediaSelectorMode) {
        case 'image':
          return {
            targetTypes: ['images'],
          }
        case 'gallery':
          return {
            targetTypes: ['images'],
            multipleSelection: true
          }
        case 'audio':
          return {
            targetTypes: ['audios'],
          }
        case 'video':
          return {
            targetTypes: ['videos'],
          }
        case undefined:
          return undefined
      }
    },
    [mediaSelectorMode]
  );

  const trMediaSelector = Translations.Translate(Translations, 'MediaManagerSelector');

  const availableBuckets = useMemo<Bucket[]>(
    () => mediaSelectorMode === 'video' && profile.edConfig?.vimeoIntegration === true
      ? [{ key: `vimeo`, label: trMediaSelector(`defaultVimeoBucket`), useOnlyPart: 'protected', group: 'default', nonAdmin: true }]
      : cmsBuckets,
    [cmsBuckets, mediaSelectorMode, profile.edConfig?.vimeoIntegration, trMediaSelector]
  )


  const [accordionManagerConfig, setAccordionManagerConfig] = useState<AccordionManagerConfig>({
    enabled: false
  });

  const [showWidgetSelector, setShowWidgetSelector] = useState<boolean>(false);
  const [showFormSelector, setShowFormSelector] = useState<boolean>(false);

  const [editor, setEditor] = useState<any>();

  const { field, fieldState } = useController({ name: 'content', control });

  const content = field.value;

  const tr: TranslateFunction = Translations.Translate(Translations, 'Cms');



  let editingElem: any = undefined;
  let editingAccordion: CmsAccordion | undefined = undefined;

  const onChange = (content: any) => {
    field.onChange(proxifyContent(magazine, content));
  }

  const openMediaSelector = async (editor: any | undefined, mode: MediaSelectorMode) => {
    setEditor(editor);
    setMediaSelectorMode(mode)
  }

  const onCloseMediaSelector = () => {
    setMediaSelectorMode(undefined)
    setEditor(undefined);
  }

  const selectMediaHandler = (mediaUrl: string[], file?: any[]) => {
    if (!editor || !mediaSelectorConfig || _.isEmpty(mediaUrl)) {
      return;
    }

    const { targetTypes, multipleSelection } = mediaSelectorConfig

    if (_.first(targetTypes) == 'images') {

      if (!multipleSelection) { //add single inline image
        const url = MediaHelper.proxify(magazine, mediaUrl[0]);
        const elem = TinyMceHelper.images.getSelectedImageElement(editor);
        if (elem?.matches('img')) { //replacing existing image
          elem.setAttribute('src', url);
          elem.setAttribute('data-mce-src', url);
        } else { //inserting new image
          const id = editor.dom.uniqueId('o4cImage_')
          editor.insertContent(`<img id="${id}" class="${TinyMceHelper.images.IMAGE_ED_CLASS} ${TinyMceHelper.images.DEFAULT_IMAGE_SIZE_CLASS}" src="${url}"/><p></p>`);
          const newImageElem = editor.dom.get(id);
          const centeredStyle = `display: block; margin-left: auto; margin-right: auto;`;
          newImageElem.setAttribute('style', centeredStyle);
          newImageElem.setAttribute('data-mce-style', centeredStyle);
          newImageElem.setAttribute('data-mce-selected', '1');
          newImageElem.removeAttribute('id');
        }
      } else {  //add inline image gallery
        const imgs = _.map(mediaUrl, (mu) => {
          const url = MediaHelper.proxify(magazine, mu);
          return `<img src="${url}"/>`
        })
        editor.insertContent(`<p></p><div class="o4c-gallery deletable"><div>${imgs.join(' ')}</div></div><p></p>`);
      }

    } else if (_.first(targetTypes) == 'audios') { //add single inline audio
      const url = MediaHelper.proxify(magazine, mediaUrl[0]);
      editor.insertContent(`<p></p><div class="o4c-audio deletable" data-url="${url}"><div><audio controls src="${url}" preload="none"></audio></div></div><p></p>`);
    } else if (_.first(targetTypes) == 'videos' && file && !_.isEmpty(file)) { //add vimeo video
      editor.insertContent(`<p></p>${file[0].integration?.embed}<p></p>`);
    }

    onCloseMediaSelector();
  }

  const openWidgetSelector = (editor?: any) => {
    setShowWidgetSelector(true);
    setEditor(editor);
  }

  const selectWidgetHandler = (widget: Widget) => {
    if (!editor) {
      return;
    }
    editor.insertContent(`<p></p><div class="o4c-widget deletable" data-widget-id="${widget.id}"  data-widget-type="${widget.type}"><div>${widget.title}</div></div><p></p>`);
    setShowWidgetSelector(false);
  }

  const openFormSelector = (editor?: any) => {
    setShowFormSelector(true);
    setEditor(editor);
  }

  const selectFormHandler = (form: Form) => {
    if (!editor) {
      return;
    }
    editor.insertContent(`<p></p><div class="o4c-form deletable" data-form-id="${form.id}"  data-form-type="${form.type}"><div>${form.title}</div></div><p></p>`);
    setShowFormSelector(false);
  }

  const openAccordionManager = (editor?: any) => {
    setAccordionManagerConfig({ enabled: true });
    setEditor(editor);
  }

  const openAccordionManagerForEdit = (editor: any, a: CmsAccordion, elem: any) => {
    setAccordionManagerConfig({ enabled: true, editingAccordion: a, editingElem: elem })
    setEditor(editor);
  }

  const insertAccordionHandler = (content: string) => {
    // console.log('content: ', content.replace(/<p><\/p>/g,''));
    if (editor) {
      if (accordionManagerConfig.editingElem) {
        $(accordionManagerConfig.editingElem).replaceWith(content.replace(/<p><\/p>/g, ''));
        editor.focus();
      } else {
        editor.insertContent(content);
      }

    }
    setAccordionManagerConfig({ enabled: false });
  }



  const deleteElement = (editor: any, elem: any) => {
    if (editor) {
      if (elem) {
        $(elem).remove();
      }
    }
  }

  return (
    <div className={styles.CmsContentEditor}>
      <Editor
        apiKey='v9p14euas3zrlnc7xrqdijyq8vqh2py2b251t9k62eup96n7'
        value={content}
        init={{
          deprecation_warnings: false,
          branding: false,
          height: '100%',
          contextmenu: 'image accordion deletable',
          menubar: false,
          resize: false,
          plugins: `
            importcss 
            autolink 
            autosave 
            image 
            link 
            table 
            charmap 
            pagebreak 
            anchor 
            lists 
            wordcount 
            media 
            emoticons 
            linkchecker
            quickbars
          `,
          toolbar: [
            `undo redo  | blocks | bold italic | icsMedia icsElements | toggleExtraToolbar`,
            `bullist numlist | blockquote emoticons charmap | link anchor pagebreak`,
          ],
          quickbars_insert_toolbar: false,
          quickbars_selection_toolbar: false,
          quickbars_image_toolbar: `imgSize | alignleft aligncenter | imgChange imgSettings | remove`,
          sandbox_iframes: false,
          toolbar_mode: 'floating',
          toolbar_sticky: true,
          autosave_interval: '5s',
          autosave_ask_before_unload: true,
          block_formats: 'Paragraph=p; Heading 1=h1; Heading 2=h2; Heading 3=h3; Heading 4=h4; Heading 5=h5; Preformatted=pre',
          image_description: true,
          image_caption: true,
          image_dimensions: false,
          object_resizing: false,
          table_toolbar: 'tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | tablemergecells',
          table_appearance_options: false,
          table_advtab: false,
          table_cell_advtab: false,
          table_row_advtab: false,
          menu: { table: { title: 'Table', items: 'cell' } },
          content_css: '/assets/TinyContent.css',
          pagebreak_separator: `<span class="pageBreak"></span>`,
          image_class_list: [
            { title: 'Full', value: TinyMceHelper.images.fullImageClassListWithSize(IMAGE_SIZE.full) },
            { title: 'Large', value: TinyMceHelper.images.fullImageClassListWithSize(IMAGE_SIZE.large) },
            { title: 'Medium', value:TinyMceHelper.images.fullImageClassListWithSize(IMAGE_SIZE.medium) },
            { title: 'Small', value: TinyMceHelper.images.fullImageClassListWithSize(IMAGE_SIZE.small) },
            { title: 'Original', value: TinyMceHelper.images.fullImageClassListWithSize(IMAGE_SIZE.original) },
            {
              title: 'Float', menu: [
                { title: 'Float Medium', value: TinyMceHelper.images.fullImageClassListWithSizeAndAlignment(IMAGE_SIZE.medium,IMAGE_ALIGNMENT.left) },
                { title: 'Float Small', value: TinyMceHelper.images.fullImageClassListWithSizeAndAlignment(IMAGE_SIZE.small,IMAGE_ALIGNMENT.left) },
                { title: 'Float Original', value:TinyMceHelper.images.fullImageClassListWithSizeAndAlignment(IMAGE_SIZE.original,IMAGE_ALIGNMENT.left) }
              ]
            }

          ],
          target_list: false,
          link_title: false,
          formats: {
            underline: { inline: 'u', exact: true },
            strikethrough: { inline: 'del', exact: true }
          },
          linkchecker_preprocess: (data: any) => {

            const deProxifiedUrl = MediaHelper.deProxify(magazine, data.url) || data.url;

            if (!MediaHelper.isFromProtectedIcsBucket(magazine, deProxifiedUrl)) {
              return { url: deProxifiedUrl }
            } else {
              return { url: `${window.location.hostname}` }
            }


          },
          setup: (editor: any) => {
            let extraToolbarVisible = false;

            TinyMceHelper.icons.addCustomIconPackToEditor(editor, CMS_CUSTOM_ICON_PACK);

            editor.ui.registry.addButton('icsAI', {
              text: 'AI Assistant',
              icon: 'ai',
              tooltip: 'AI',
              enabled: false,
              onAction: () => {
                // openMediaSelector(editor, 'image')
              }
            })
            editor.ui.registry.addButton('toggleExtraToolbar', {
              icon: 'more-drawer',
              tooltip: 'More options',
              onAction: () => {
                const extraToolbar = editor.getContainer().querySelector('.tox-toolbar:last-of-type');
                extraToolbarVisible = !extraToolbarVisible;
                if (extraToolbarVisible) {
                  extraToolbar.style.display = 'flex';
                } else {
                  extraToolbar.style.display = 'none';
                }
              }
            })
            editor.ui.registry.addMenuButton('icsMedia', {
              text: 'Add media',
              icon: 'addMedia',
              tooltip: 'Add media',
              fetch: (callback: any) => {
                const items = [
                  {
                    type: "menuitem",
                    icon: 'image',
                    text: 'Add image',
                    onAction: () => {
                      openMediaSelector(editor, 'image')
                    }
                  },
                  {
                    type: "menuitem",
                    icon: 'gallery',
                    text: 'Add gallery',
                    onAction: () => {
                      openMediaSelector(editor, 'gallery');
                    }
                  },
                  {
                    type: "menuitem",
                    icon: 'video',
                    text: 'Add video',
                    onAction: () => {
                      openMediaSelector(editor, 'video')
                    }
                  },
                  {
                    type: "menuitem",
                    icon: 'audio',
                    text: 'Add audio',
                    onAction: () => {
                      openMediaSelector(editor, 'audio')
                    }
                  },
                  {
                    type: "menuitem",
                    icon: 'embed',
                    text: 'Add external media',
                    onAction: () => {
                      editor.execCommand('mceMedia');
                    }
                  }
                ]
                callback(items);
              }
            })
            editor.ui.registry.addMenuButton('icsElements', {
              text: 'Add elements',
              icon: 'addElement',
              tooltip: 'Add elements',
              fetch: (callback: any) => {
                const items = [
                  {
                    type: "menuitem",
                    icon: 'form',
                    text: 'Add form',
                    onAction: () => {
                      openFormSelector(editor)
                    }
                  },
                  {
                    type: "menuitem",
                    icon: 'accordion',
                    text: 'Add accordion',
                    onAction: () => {
                      openAccordionManager(editor)
                    }
                  },
                  {
                    type: "menuitem",
                    icon: 'widget',
                    text: 'Add widget',
                    onAction: () => {
                      openWidgetSelector(editor)
                    }
                  },
                  {
                    type: "menuitem",
                    icon: 'table',
                    text: 'Add table',
                    onAction: () => {
                      editor.execCommand('mceInsertTableDialog');
                    }
                  }
                ]
                callback(items);
              }
            })

            editor.ui.registry.addMenuItem('accordion', {
              icon: 'accordion',
              text: 'Edit accordion',
              onAction: () => {
                if (editingAccordion) {
                  openAccordionManagerForEdit(editor, editingAccordion, editingElem);
                }
              }
            })
            editor.ui.registry.addMenuItem('delete', {
              icon: 'remove',
              text: 'Delete',
              onAction: () => {
                if (editingElem) {
                  deleteElement(editor, editingElem);
                }
              }
            })
            editor.ui.registry.addContextMenu('accordion', {
              update: (element: any) => {
                if (element.classList.contains('accordion')) {
                  return 'accordion delete';
                }
                return '';

              }
            });
            editor.ui.registry.addContextMenu('deletable', {
              update: (element: any) => {
                if (element.classList.contains('deletable')) {
                  return 'delete';
                }
                return '';

              }
            });
            editor.on('contextmenu', (e: any) => {
              const elem = e.target;
              if (elem.classList.contains('accordion')) {
                editingElem = elem;
                const thePanels: AccordionPanel[] = [];
                $(elem).find('.panel').each((i: any, el: any) => {
                  thePanels.push({
                    header: _.trim($(el).find('.title').text()) || '',
                    body: _.trim($(el).find('.body > .text-component').text()) || ''
                  })
                })
                editingAccordion = { panels: [...thePanels] };


              }
              if (elem.classList.contains('deletable')) {
                editingElem = elem;
              }

            })
            editor.on('OpenWindow', (e: any) => {

              try {
                const originalData = e.dialog.getData();
                const srcUrl = originalData.src?.value;
                if (srcUrl !== undefined) {
                  e.dialog.setData({ ...originalData, src: { ...originalData.src, value: MediaHelper.deProxify(magazine, srcUrl) || srcUrl } })
                  return;
                }
                const linkUrl = originalData.url?.value;
                if (linkUrl !== undefined) {
                  e.dialog.setData({ ...originalData, url: { ...originalData.url, value: MediaHelper.deProxify(magazine, linkUrl) || linkUrl } })
                }
              } catch (error) {
                console.error(error);

              }
            });
            editor.on('CloseWindow', (e: any) => {

              try {
                const originalData = e.dialog.getData();

                if(originalData.caption === undefined) { //not image dialog
                  return;
                }

                TinyMceHelper.images.updateClassesOfParentFigure(editor);

              } catch (error) {
                console.error(error);

              }
            });

            editor.ui.registry.addContextToolbar('hyperlink', {
              predicate: (node: any) => node.nodeName.toLowerCase() === 'a',
              items: 'link unlink',
              position: 'node',
              scope: 'node'
            });

            editor.on('ExecCommand', (e: any) => {
              if (e.command === 'JustifyLeft') {
                TinyMceHelper.images.alignImage(editor,IMAGE_ALIGNMENT.left);
              } else if (e.command === 'JustifyCenter') {
                TinyMceHelper.images.alignImage(editor,IMAGE_ALIGNMENT.center);
              }
            });

            editor.ui.registry.addMenuButton('imgSize', {
              text: 'Image size',
              icon: 'edit-image',
              tooltip: 'Image size',
              fetch: (callback: any) => {
                const items = [
                  TinyMceHelper.images.addImageSizeOption(editor, IMAGE_SIZE.full),
                  TinyMceHelper.images.addImageSizeOption(editor, IMAGE_SIZE.large),
                  TinyMceHelper.images.addImageSizeOption(editor, IMAGE_SIZE.medium),
                  TinyMceHelper.images.addImageSizeOption(editor, IMAGE_SIZE.small),
                  TinyMceHelper.images.addImageSizeOption(editor, IMAGE_SIZE.original),
                ]
                callback(items);
              },
              onSetup: (api: any) => {
                api.setText(TinyMceHelper.images.selectedImageSizeOptionText(editor));
              }
            });

            editor.ui.registry.addButton('imgSettings', {
              text: '',
              icon: 'preferences',
              tooltip: 'Image settings',
              onAction: (api: any) => {
                editor.execCommand('mceImage');
              }
            });

            editor.ui.registry.addButton('imgChange', {
              text: '',
              icon: 'image',
              tooltip: 'Change image',
              onAction: (api: any) => {
                openMediaSelector(editor, 'image');
              }
            });

          }
        }}
        onEditorChange={onChange}
      />
      {mediaSelectorConfig &&
        <MediaSelector
          magazine={magazine}
          profile={profile}
          closeHandler={onCloseMediaSelector}
          selectHandler={selectMediaHandler}
          dedicatedPart={'protected'}
          availableBuckets={availableBuckets}
          restrictedMediaTypes={mediaSelectorConfig.targetTypes}
          multipleSelection={mediaSelectorConfig.multipleSelection}
        />}
      {showWidgetSelector &&
        <WidgetSelector
          magazine={magazine}
          closeHandler={() => setShowWidgetSelector(false)}
          selectHandler={selectWidgetHandler}
        />}
      {showFormSelector &&
        <FormSelector
          magazine={magazine}
          profile={profile}
          closeHandler={() => setShowFormSelector(false)}
          selectHandler={selectFormHandler}
        />}
      {accordionManagerConfig.enabled &&
        <CmsAccordionManager
          magazine={magazine}
          closeHandler={() => setAccordionManagerConfig({ enabled: false })}
          insertHandler={insertAccordionHandler}
          editingAccordion={accordionManagerConfig.editingAccordion}
        />}

    </div>
  )
}